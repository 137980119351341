import React from "react"
import ReactDOMServer from "react-dom/server"
import { EmailTableRow } from "./emailTemplate"
import formatDate from "../../utilities/formatDate"
import getFullStateName from "../../utilities/getFullStateName"

const ReportClaimEmailTemplate = (formData) => {
  return ReactDOMServer.renderToString(
    <table>
      <EmailTableRow
        label="Your Name:"
        value={`${formData.firstName} ${formData.lastName}`}
      />
      <EmailTableRow label="Your Phone:" value={formData.phone} />
      <EmailTableRow label="Your Email:" value={formData.email} />
      <EmailTableRow label="Your Organization:" value={formData.organization} />
      <EmailTableRow
        label="Relationship To The Claim:"
        value={formData.relationship}
      />
      <EmailTableRow label="Insured Name:" value={formData.insuredName} />
      <EmailTableRow
        label="Insured Cell Or Other Phone:"
        value={formData.insuredPhone}
      />
      <EmailTableRow
        label="Date Of Loss:"
        value={formatDate(formData.dateOfLoss)}
      />
      <EmailTableRow
        label="State Where Loss Occurred:"
        value={getFullStateName(formData.occuredState)}
      />
      <EmailTableRow
        label="Description Of Loss:"
        value={formData.lossDescription}
      />
      <EmailTableRow
        label="Insured Policy Number:"
        value={formData.insuredPolicyNumber}
      />
      <EmailTableRow label="Email Copy To:" value={formData.emailTo} />
    </table>
  )
}

export default ReportClaimEmailTemplate
