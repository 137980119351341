import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import {
  ErrorMessages,
  States,
  Relationships,
  EmailSettings
} from "../../constants"
import {
  TrvInput,
  TrvEmailInput,
  TrvPhoneInput,
  TrvTextArea,
  TrvDropdown,
  TrvDateInput
} from "trv-ebus-tcom-reactcomponents"
import { TdsAlert } from "@trv-tds/react"
import ReportClaimEmailTemplate from "../email-templates/reportClaimEmailTemplate"
import { v4 as uuidv4 } from "uuid"
import "./scss/main.scss"
import { store } from "../../state/store"
import Recaptcha from "../../components/recaptcha/recaptcha"
import getFullStateName from "../../utilities/getFullStateName"
import {emailHeaders, emailPayload} from '../email-templates/emailRequest'
import formatDate from "../../utilities/formatDate"
import {
  recaptchaCalled,
  sendEmailPayloads,
  sentWithRecaptcha
} from "../../services/formsService"
import "../styles.scss"

const ReportAClaimForm = () => {
  const [isClient, setIsClient] = useState(false)
  const [isServiceError, setIsServiceError] = useState(false)
  const [isGoogleServiceError, setIsGoogleServiceError] = useState(false)
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false)
  const [emailBody, setEmailBody] = useState(null)
  const returnUrl = "/claim-reporting/thankyou"

  const alertRef = useRef(null)

  const state = store.getState()

  const recaptchaEnabled =
    state.config.RECAPTCHA_ENABLED === "true" ? true : false

  useEffect(() => {
    setIsClient(true)
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors
  } = useForm()

  const googleServiceErrorHandler = () => {
    setIsGoogleServiceError(true)
  }

  const onSubmit = async (formdata) => {
    if (!isGoogleServiceError) {
      setIsSubmissionInProgress(true)
      const body = ReportClaimEmailTemplate(formdata)
      let formPayload = {}
      formPayload = {
          email_body: {
            "topic": state.config.EEF_EVENT,
            "payload": {
                ...emailPayload('REPORT_A_CLAIM', state.config,formdata),
                "metaData": [
                    {
                        "key": "ClaimantName",
                        "value": `${formdata.firstName} ${formdata.lastName}`
                    },
                    {
                        "key": "ClaimantPhone",
                        "value": formdata.insuredPhone
                    },
                    {
                        "key": "ClaimantOrganization",
                        "value": formdata.organization
                    },
                    {
                        "key": "ClaimantRelationshiptoClaim",
                        "value": formdata.relationship
                    },
                    {
                        "key": "InsuredName",
                        "value": formdata.insuredName
                    },
                    {
                        "key": "InsuredPhone",
                        "value": formdata.insuredPhone
                    },
                    {
                        "key": "DateOfLoss",
                        "value": formatDate(formdata.dateOfLoss)
                    },
                    {
                        "key": "LossState",
                        "value": getFullStateName(formdata.occuredState)
                    },
                    {
                        "key": "LossDescription",
                        "value": formdata.lossDescription
                    },
                    {
                        "key": "PolicyNumber",
                        "value": formdata.insuredPolicyNumber
                    },
                    {
                        "key": "AppData",
                        "value": state.config.APP_CODE_REPORT_CLAIM
                    },
                    {
                        "key": "sourceSiloRegion",
                        "value": state.config.EVENT_REGION
                    },
                    {
                        "key": "CCEmailDisplay",
                        "value": formdata.emailTo
                    },
                    {
                        "key": "YourEmailDisplay",
                        "value": formdata.email
                    },
                    {
                        "key": "CCEmail",
                        "value": formdata.emailTo
                    }
                ]
            },
            "headers": emailHeaders(state.config)
          }
        }

      if (recaptchaCalled()) {
        setEmailBody(formPayload)
      } else {
        const emailSent = await sendEmailPayloads(formPayload, returnUrl)
        if (!emailSent) {
          serviceErrorHandler()
        }
        setIsSubmissionInProgress(false)
      }
    } else {
      serviceErrorHandler()
    }
  }

  const submitWithRecaptcha = async (recaptchaResponse, siteType) => {
    const emailSent = await sentWithRecaptcha(
      recaptchaResponse,
      siteType,
      emailBody,
      returnUrl
    )

    if (!emailSent) {
      serviceErrorHandler()
    }
    setIsSubmissionInProgress(false)
  }

  const serviceErrorHandler = () => {
    setIsServiceError(true)
    window.scrollTo(0, alertRef.current.offsetTop - 100)
  }

  return isClient ? (
    <>
      {isServiceError && (
        <TdsAlert type="error" ref={alertRef}>
          {ErrorMessages.Service_Error}
        </TdsAlert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {recaptchaEnabled && (
          <Recaptcha
            serviceCall={submitWithRecaptcha}
            siteType={state.config.RECAPTCHA_SITE_TYPE}
            googleServiceError={googleServiceErrorHandler}
          />
        )}
        <h3 className="tds-margin-top-xx-large">Your Information</h3>
        <div className="tds-row tds-margin-top-x-large">
          <div className="tds-col-xl-8">
            <TrvInput
              register={register}
              name="firstName"
              label="First Name"
              required={true}
              errors={errors.firstName}
              maxLength="120"
              validityMessages={{
                required: ErrorMessages.Empty_FirstName
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvInput
              register={register}
              name="lastName"
              label="Last Name"
              required={true}
              errors={errors.lastName}
              maxLength="120"
              validityMessages={{
                required: ErrorMessages.Empty_LastName
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-6">
            <TrvPhoneInput
              register={register}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              name="phone"
              label="Phone Number"
              minLength={10}
              pattern={/^([0-9]{3})([0-9]{3})([0-9]{4})$/}
              formatPattern="($1) $2-$3"
              required={true}
              errors={errors.phone}
              validityMessages={{
                required: ErrorMessages.Empty_Phone,
                minLength: ErrorMessages.Invalid_Phone
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvEmailInput
              register={register}
              name="email"
              label="Email"
              required={true}
              errors={errors.email}
              validityMessages={{
                required: ErrorMessages.Empty_Email,
                pattern: ErrorMessages.WrongFormat_Email
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvInput
              register={register}
              name="organization"
              label="Your Organization"
              required={true}
              errors={errors.organization}
              validityMessages={{
                required: ErrorMessages.Empty_Organization
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvDropdown
              register={register}
              name="relationship"
              label="Relationship To The Claim"
              options={Relationships}
              required={true}
              hint="Select Your Relationship..."
              defaultValue=""
              errors={errors.relationship}
              validityMessages={{
                required: ErrorMessages.Empty_Relationship
              }}
            />
          </div>
        </div>
        <h3 className="tds-margin-top-x-large">
          Information of the Insured and the Loss
        </h3>
        <div className="tds-row tds-margin-top-x-large">
          <div className="tds-col-xl-8">
            <TrvInput
              register={register}
              name="insuredName"
              label="Insured Name"
              required={true}
              errors={errors.insuredName}
              maxLength="120"
              validityMessages={{
                required: ErrorMessages.Empty_InsuredName
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvPhoneInput
              register={register}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              name="insuredPhone"
              label="Insured Phone"
              minLength={10}
              pattern={/^([0-9]{3})([0-9]{3})([0-9]{4})$/}
              formatPattern="($1) $2-$3"
              required={true}
              errors={errors.insuredPhone}
              validityMessages={{
                required: ErrorMessages.Empty_InsuredPhone,
                minLength: ErrorMessages.Invalid_Phone
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-6">
            <TrvInput
              register={register}
              name="insuredPolicyNumber"
              label="Insured Policy Number"
              required={true}
              pattern={/^[a-zA-Z0-9]*$/}
              errors={errors.insuredPolicyNumber}
              maxLength="9"
              className="tlPrivate"
              validityMessages={{
                required: ErrorMessages.Empty_InsuredPolicyNumber,
                pattern: ErrorMessages.Invalid_PolicyNumber
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-6">
            <TrvDateInput
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              name="dateOfLoss"
              label="Date Of Loss"
              max={new Date()}
              required={true}
              errors={errors.dateOfLoss}
              validityMessages={{
                required: ErrorMessages.Empty_DateOfLoss,
                dateValidity: ErrorMessages.Invalid_DateOfLoss,
                dateRange: ErrorMessages.Range_DateOfLoss
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-6">
            <TrvDropdown
              register={register}
              name="occuredState"
              label="State Where Loss Occured"
              options={States}
              required={true}
              hint="Select a State..."
              defaultValue=""
              errors={errors.occuredState}
              validityMessages={{
                required: ErrorMessages.Empty_State_LossOccurred
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvTextArea
              register={register}
              name="lossDescription"
              label="Description Of Loss"
              rows={4}
              cols={20}
              required={true}
              errors={errors.lossDescription}
              validityMessages={{
                required: ErrorMessages.Empty_DescriptionOfLoss
              }}
            />
          </div>
        </div>
        <div className="tds-row tds-margin-top-large">
          <div className="tds-col-xl-8">
            <TrvEmailInput
              name="emailTo"
              label="Please Email A Copy Of This To"
              register={register}
              required={true}
              errors={errors.emailTo}
              maxLength="254"
              validityMessages={{
                required: ErrorMessages.Empty_EmailClaimTo,
                pattern: ErrorMessages.WrongFormat_Email
              }}
            />
          </div>
        </div>
        <button
          className="tds-button--primary tds-margin-top-large"
          aria-busy={isSubmissionInProgress}
          type="submit">
          SUBMIT
        </button>
      </form>
    </>
  ) : null
}
export default ReportAClaimForm
